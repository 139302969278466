import appService from '@/services/app.service';

const getAgencies = (req) => {
  return appService.get('/agencies', req);
}

const getAgency = agencyId => {
  return appService.get(`/agencies/${agencyId}`);
};

const getUsers = agencyId => {
  return appService.get(`/agencies/${agencyId}/users`);
};

export const agencyQueryService = {
  getAgencies,
  getAgency,
  getUsers,
};
